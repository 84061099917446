<template>
  <div>
    <b-card class="mb-2 p-1" no-body>
      <b-form autocomplete="off" id="export-form">
        <div class="d-flex justify-content-between">
          <p class="collapse-title h5">Export</p>
          <div>
            <b-button
              type="submit"
              @click.prevent="formSubmitted"
              variant="primary"
              >Export</b-button
            >
          </div>
        </div>
        <b-row>
          <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
            <label>Export</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="data_local.moduleFilter"
              item-text="moduleFilter"
              :options="moduleOptions"
              @input="(val) => moduleFilterHasChanged(val)"
              class="w-100"
              placeholder="Select Module.."
            />
          </b-col>
          <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
            <label>File Type</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="data_local.fileType"
              item-text="fileType"
              :clearable="false"
              :reduce="(val) => val.value"
              :options="fileTypeOptions"
              class="w-100"
              placeholder="Select File Type.."
            />
          </b-col>
          <b-col
            v-if="['Shifts', 'Employee'].includes(data_local.moduleFilter)"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <label>Status</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="data_local.statusFilter"
              :options="statusOptions"
              class="w-100"
              :reduce="(val) => val.code"
              placeholder="Select Status.."
            />
          </b-col>
          <b-col
            v-if="['Candidate'].includes(data_local.moduleFilter)"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <label>Status</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="data_local.candidateStatusFilter"
              :options="candidateStatusOptions"
              class="w-100"
              :reduce="(val) => val.code"
              placeholder="Select Status.."
            />
          </b-col>
          <b-col
            v-if="['Employee'].includes(data_local.moduleFilter)"
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Role</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="data_local.roleFilter"
              :options="roleOptions"
              :reduce="(val) => val.code"
              class="w-100"
              placeholder="Select Role.."
            />
          </b-col>

          <b-col
            v-if="['Employee', 'Candidate'].includes(data_local.moduleFilter)"
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Designation</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="data_local.designationFilter"
              :options="designationOptions"
              class="w-100"
              :reduce="(val) => val.code"
              placeholder="Select Designation.."
            />
          </b-col>
          <b-col
            v-if="
              ['Employee', 'Leave Balance', 'Company Tree', 'Swipes'].includes(
                data_local.moduleFilter
              )
            "
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <label>By Reporting Manager</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="data_local.managerFilter"
              :options="managerOptions"
              class="w-100"
              :reduce="(val) => val.code"
              @input="(val) => getSubordinateEmployee(val)"
              placeholder="Select By Manager.."
            />
          </b-col>
          <b-col
            v-if="
              ['Employee', 'Holiday Calendar'].includes(data_local.moduleFilter)
            "
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <label>Shift</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="data_local.shiftFilter"
              :options="shiftOptions"
              class="w-100"
              :reduce="(val) => val.code"
              placeholder="Select By Shift.."
            />
          </b-col>
          <b-col
            v-if="['Employee'].includes(data_local.moduleFilter) && false"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <label>Country</label>
            <v-select
              v-model="data_local.countryFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="countryOptions"
              :filter-by="myCountryFilter"
              :clearable="true"
              input-id="country"
              label="name"
              :reduce="(option) => option.name"
              placeholder="Select Country.."
            >
              <template slot="option" slot-scope="option">
                <span :class="['flag-icon', `flag icon-${option.name}`]"></span>
                {{ option.name }} ({{ option.code }})
              </template>

              <template slot="selected-option" slot-scope="option">
                <span :class="['flag-icon', `flag icon-${option.name}`]"></span>
                {{ option.name }} ({{ option.code }})
              </template>
            </v-select>
          </b-col>
          <b-col
            v-if="['Employee'].includes(data_local.moduleFilter) && false"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <label>Experience In Company</label>
            <b-form-spinbutton
              v-model="data_local.experienceFilter"
              min="0"
              max="100"
            />
          </b-col>
          <b-col
            v-if="['Candidate'].includes(data_local.moduleFilter)"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <label>Referred By</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="data_local.referredByFilter"
              :options="employeeOptions"
              class="w-100"
              :reduce="(val) => val.code"
              placeholder="Select Referred By.."
            />
          </b-col>
          <b-col
            v-if="['Candidate'].includes(data_local.moduleFilter)"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <label>Recruited By</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="data_local.recruitedByFilter"
              :options="employeeOptions"
              class="w-100"
              :reduce="(val) => val.code"
              placeholder="Select Recruited By.."
            />
          </b-col>
          <b-col
            v-if="['Candidate'].includes(data_local.moduleFilter)"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <label>
              <b-form-checkbox
                v-model="by_interviews_count"
                name="checkbox-1"
                value="accepted"
                unchecked-value="not_accepted"
              >
                By Interviews Count
              </b-form-checkbox>
            </label>
            <b-form-spinbutton
              v-if="by_interviews_count == 'accepted'"
              v-model="data_local.byInterviewsCountFilter"
              min="0"
              max="20"
            />
          </b-col>
          <b-col
            v-if="['Candidate'].includes(data_local.moduleFilter)"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <label>By Source</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="data_local.sourceByFilter"
              :options="sourceOptions"
              class="w-100"
              :reduce="(val) => val.code"
              placeholder="Select Source.."
            />
          </b-col>
          <b-col
            v-if="
              ['Leave Balance', 'Swipes', 'Karma Request'].includes(
                data_local.moduleFilter
              )
            "
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <label>By Employee</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="data_local.employeeByFilter"
              :options="employeeOptions"
              class="w-100"
              :reduce="(val) => val.code"
              placeholder="Select Employee.."
            />
          </b-col>
          <b-col
            v-if="['Swipes'].includes(data_local.moduleFilter)"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <label>By Employee Mood</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="data_local.moodByFilter"
              :options="moodOptions"
              class="w-100"
              :reduce="(val) => val.code"
              placeholder="Select Employee Mood.."
            />
          </b-col>
          <b-col
            v-if="['Swipes'].includes(data_local.moduleFilter)"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <b-form-group label="From Date Range" label-for="date_range">
              <flat-pickr
                ref="date_range"
                v-model="data_local.date_range"
                :config="dateConfig"
                class="form-control"
                input-id="date_range"
                placeholder="Select Start and End Date"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="['Holiday Calendar'].includes(data_local.moduleFilter)"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <label>By Holiday Type</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="data_local.holidayTypeByFilter"
              :options="holidayTypeOption"
              class="w-100"
              placeholder="Select Holiday Type.."
            />
          </b-col>
          <b-col
            v-if="['Company Tree'].includes(data_local.moduleFilter) && false"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <label>Department</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="data_local.departmentByFilter"
              :options="departmentOption"
              label="label"
              :reduce="(val) => val.code"
              class="w-100"
              placeholder="Select Department.."
            />
          </b-col>
          <b-col
            v-if="['Company Tree'].includes(data_local.moduleFilter) && false"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <label>By Subordinates Count</label>
            <b-form-input
              name="bySubordinatesCountFilter"
              type="number"
              min="0"
              v-model.number="data_local.bySubordinatesCountFilter"
            />
          </b-col>
          <b-col
            v-if="['Job Opening'].includes(data_local.moduleFilter)"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Job Urgency Status"
              label-for="job-urgency-status"
            >
              <v-select
                v-model="data_local.urgencyStatusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="vacancyUrgencyStatusOption"
                :reduce="(label) => label.value"
                label="label"
                name="urgency_status"
                :clearable="true"
                input-id="job-urgency-status"
                placeholder="Select Urgency Status.."
                :searchable="false"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="['Job Opening'].includes(data_local.moduleFilter)"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <b-form-group label="Job Status" label-for="job-status">
              <v-select
                v-model="data_local.jobStatusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="vacancyStatusOption"
                :reduce="(label) => label.value"
                label="label"
                name="status"
                :clearable="true"
                input-id="job-status"
                placeholder="Select Status.."
                :searchable="false"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="['Job Opening'].includes(data_local.moduleFilter)"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <b-form-group label="Job Type" label-for="job-type">
              <v-select
                v-model="data_local.vacancyTypeFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="vacancyTypeOption"
                :reduce="(label) => label.value"
                label="label"
                name="vacancy_type"
                :clearable="true"
                input-id="job-type"
                placeholder="Select Job Type.."
                :searchable="false"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="
              ['Karma LeaderBoard', 'Karma Request','User Karma Points'].includes(
                data_local.moduleFilter
              )
            "
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <b-form-group label-for="karma-program">
              <label class="required">Karma Program</label>
              <v-select
                v-model="data_local.karmaProgramFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="karmaProgramOption"
                :reduce="(label) => label.hashid"
                @input="(val) => getCategories(val)"
                label="label"
                name="karma_program"
                :clearable="true"
                input-id="karma-program"
                placeholder="Select Karma Program.."
                :searchable="false"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="['Karma Request'].includes(data_local.moduleFilter)"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <b-form-group label-for="karma-category">
              <label class="">Karma Category</label>
              <v-select
                v-model="data_local.karmaCategoryFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="karmaCategoryOption"
                :reduce="(label) => label.hashid"
                @input="(val) => getChildCategories(val)"
                label="label"
                name="karma_category"
                :clearable="true"
                input-id="karma-category"
                placeholder="Select Karma Category.."
                :searchable="false"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="['Karma Request'].includes(data_local.moduleFilter)"
            cols="12"
            md="4"
            sm="6"
            class="mb-md-0 mb-2"
          >
            <b-form-group label-for="sub-karma-category">
              <label class="">Sub Karma Category</label>
              <v-select
                v-model="data_local.subKarmaCategoryFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="subKarmaCategoryOption"
                :reduce="(label) => label.hashid"
                label="label"
                name="sub_karma_category"
                :clearable="true"
                input-id="sub_karma-category"
                placeholder="Select Sub Karma Category.."
                :searchable="false"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <b-card-text class="mt-1">
          <p>Task 1</p>
          <b-progress
            :value="25"
            variant="success"
            striped
            animated
          ></b-progress>
        </b-card-text> -->
      </b-form>
    </b-card>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-if="$can('export_delete') && totalExportList"
              class="mr-2"
              v-model="isDeleteMultipleCandChecked"
            >
            </b-form-checkbox>
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              v-if="selectedExports.length == 0"
              class="d-flex align-items-center justify-content-end"
            >
              <b-input-group class="input-group-merge mr-1">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block"
                  placeholder="Search Name"
                />
              </b-input-group>
              <b-link class="text-body ml-auto" @click.prevent="refetchData()">
                <feather-icon icon="RefreshCcwIcon" size="18" class="mr-25" />
              </b-link>
            </div>
            <b-button
              v-else-if="$can('export_delete')"
              class="float-right"
              variant="danger"
              :disabled="exportsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ exportsDeleteCount }} Export</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedExports"
        name="selectedExports"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchExportList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap text-center">
              <b-form-checkbox :value="data.item.hashid"> </b-form-checkbox>
            </div>
          </template>
          <!-- Column: User -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <b-link
                v-if="$can('export_show')"
                :to="{
                  name: 'view-export',
                  params: { id: data.item.hashid },
                }"
                class="font-weight-bold d-inline-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.title"
              >
                {{ data.item.title | str_limit(15) }}
              </b-link>
              <span
                v-else
                v-b-tooltip.hover.topright.v-primary
                :title="data.item.title"
              >
                {{ data.item.title | str_limit(15) }}
              </span>
            </b-media>
          </template>

          <!--Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status == "1" ? "Active" : "In Active" }}
            </b-badge>
          </template>
          <template #cell(completed)="data">
            <b-progress
              v-if="!data.item.completed"
              :value="data.item.progress"
              variant="success"
              striped
              animated
            ></b-progress>
            <b-badge v-if="data.item.completed" pill variant="success">
              <feather-icon
                icon="CheckCircleIcon"
                size="24"
                class="align-middle"
              />
              Completed
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
              v-if="data.item.completed || $can('export_delete')"
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                v-if="data.item.completed"
                class="btn-sm p-0"
                @click.stop.prevent="openWindow(data.item.file_link)"
              >
                <feather-icon icon="DownloadIcon" size="13" class="mr-50" />
                <span>Download</span>
              </b-dropdown-item>

              <b-dropdown-item
                class="btn-sm p-0"
                @click="confirmDeleteRecord(data)"
                v-if="$can('export_delete')"
              >
                <feather-icon icon="TrashIcon" size="13" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2" v-if="totalExportList">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="selectedExports.length == 0"
              v-model="currentPage"
              :total-rows="totalExportList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <b-button
              v-else-if="$can('export_delete')"
              class="float-right"
              variant="danger"
              :disabled="exportsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ exportsDeleteCount }} Export</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSpinbutton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import exportStoreModule from "../exportStoreModule";
import useExportList from "./useExportList";
import ExportListFilters from "./ExportListFilters.vue";

let country = require("/src/libs/CountryCodes.json");

export default {
  components: {
    flatPickr,
    ExportListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    ToastificationContent,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSpinbutton,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-exports";
    const isDeleteMultipleCandChecked = ref(false);
    const selectedExports = ref([]);
    const exportsDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(REFERRAL_APP_STORE_MODULE_NAME, exportStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    const {
      fetchExportList,
      tableColumns,
      perPage,
      currentPage,
      totalExportList,
      exportsMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,
      filterByExportUrgencyStatus,
      filterByExportType,
      resolveUserStatusVariant,
      exportStatusOption,
    } = useExportList();

    watch(isDeleteMultipleCandChecked, (val) => {
      selectedExports.value = [];
      if (val) {
        exportsMetaData.value.forEach((item) => {
          selectedExports.value.push(item.hashid);
        });
      }
    });

    watch(selectedExports, (val) => {
      exportsDeleteCount.value = val.length;
    });

    return {
      // Sidebar
      fetchExportList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      totalExportList,
      // Extra Filters
      statusFilter,
      filterByExportUrgencyStatus,
      filterByExportType,
      selectedExports,
      exportsDeleteCount,
      isDeleteMultipleCandChecked,
      resolveUserStatusVariant,
      exportStatusOption,
    };
  },
  data() {
    return {
      isBusy: false,
      by_interviews_count: "",
      moduleOptions: [
        "Shifts",
        "Employee",
        "Candidate",
        "Leave Balance",
        "Holiday Calendar",
        "Company Tree",
        "Job Opening",
        "Swipes",
        "Karma LeaderBoard",
        "Karma Request",
        "User Karma Points",
      ],
      statusOptions: [
        { label: "All", code: "all" },
        { label: "Active", code: 1 },
        { label: "InActive", code: 0 },
      ],
      candidateStatusOptions: [
        { label: "All", code: "all" },
        { label: "In Progress", code: 0 },
        { label: "Selected", code: 1 },
        { label: "Rejected", code: 2 },
      ],
      moodOptions: [
        { label: "All", code: 0 },
        { label: "Frustrated", code: 1 },
        { label: "Stressed", code: 2 },
        { label: "Neutral", code: 3 },
        { label: "Focused", code: 4 },
        { label: "Energetic", code: 5 },
      ],
      data_local: {
        moduleFilter: "",
        roleFilter: "",
        statusFilter: "all",
        designationFilter: "",
        managerFilter: "",
        countryFilter: "",
        experienceFilter: null,
        referredByFilter: "",
        recruitedByFilter: "",
        byInterviewsCountFilter: "",
        employeeByFilter: "",
        holidayTypeByFilter: "",
        departmentByFilter: "",
        bySubordinatesCountFilter: "",
        urgencyStatusFilter: "",
        jobStatusFilter: "",
        candidateStatusFilter: "all",
        shiftFilter: "",
        vacancyTypeFilter: "",
        fileType: "xlsx",
        karmaProgramFilter: "",
        karmaCategoryFilter: "",
        subKarmaCategoryFilter: "",
      },
      dateConfig: {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        //inline: true,
        mode: "range",
      },
      myCountryFilter: (option, label, search) => {
        let temp = search.toLowerCase();
        return (
          option.name.toLowerCase().indexOf(temp) > -1 ||
          option.dial_code.toLowerCase().indexOf(temp) > -1 ||
          option.code.toLowerCase().indexOf(temp) > -1
        );
      },
      roleOptions: [],
      designationOptions: [],
      managerOptions: [],
      shiftOptions: [],
      countryOptions: country,
      employeeOptions: [],
      holidayTypeOption: ["All", "Fixed Holiday", "Restricted Holiday"],
      departmentOption: [],
      vacancyUrgencyStatusOption: [],
      vacancyStatusOption: [],
      vacancyTypeOption: [],
      karmaProgramOption: [],
      karmaCategoryOption: [],
      subKarmaCategoryOption: [],
      fileTypeOptions: [
        { label: "Excel", value: "xlsx" },
        { label: "CSV", value: "csv" },
      ],
      sourceOptions: [
        { label: "Naukri", code: "naukri" },
        { label: "Indeed", code: "indeed" },
        { label: "Hire a star", code: "hire_a_star" },
        { label: "Linkedin", code: "linkedin" },
        // { label: "DCKAP Careers", code: "DCKAP Careers" },
        // { label: "Other", code: "other" },
      ],
    };
  },
  watch: {
    by_interviews_count(val) {
      if (val == "not_accepted") {
        this.data_local.byInterviewsCountFilter = "";
      }
    },
  },
  created() {
    this.fetchDepartmentList();
    this.getVacanciesCustomFields();
  },
  methods: {
    moduleFilterHasChanged() {
      console.log(this.data_local.moduleFilter);
      if (this.data_local.moduleFilter == "Karma Request") {
        this.getKarmaProgramOption();
        this.fetchEmployeeList();
        return this.data_local.moduleFilter;
      }
      if (this.data_local.moduleFilter == "Karma LeaderBoard") {
        this.getKarmaProgramOption();
        return this.data_local.moduleFilter;
      }
      if (this.data_local.moduleFilter == "User Karma Points") {
        this.getKarmaProgramOption();
        return this.data_local.moduleFilter;
      }
      if (this.data_local.moduleFilter == "Employee") {
        this.fetchRolesList();
        this.fetchDesignationsList();
        this.fetchManagerList();
        this.fetchShiftsList();
        return this.data_local.moduleFilter;
      }
      if (this.data_local.moduleFilter == "Leave Balance") {
        this.fetchManagerList();
        this.fetchEmployeeList();
        return this.data_local.moduleFilter;
      }
      if (this.data_local.moduleFilter == "Candidate") {
        this.fetchEmployeeList();
        this.fetchDesignationsList();
        return this.data_local.moduleFilter;
      }
      if (this.data_local.moduleFilter == "Holiday Calendar") {
        this.fetchShiftsList();
        return this.data_local.moduleFilter;
      }
    },
    getKarmaProgramOption() {
      const self = this;
      let data = {};
      data.url = "list/karmaProgram";
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.karmaProgramOption = res.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Something went wrong',
                icon: 'BellIcon',
                variant: 'danger',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"error-info ",
              hideProgressBar : true,
            });
          this.isBusy = false;
        });
    },
    getSubordinateEmployee(employee_id)
    {
      if(!employee_id)
      {
        return true;
      }

      const self = this;
      let data = {};
      data.url = "list/my-teams-only/";
      data.params = {
        manager_id:employee_id
      }
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.employeeOptions = res.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Employeee Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Employeee Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    getCategories(karma_program_id, reset = true) {
      const self = this;
      let data = {};
      data.url = "get-category-by-program-id/" + karma_program_id;
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.karmaCategoryOption = res.data.data;
          if (reset) {
            self.data_local.karmaCategoryFilter = "";
            self.data_local.subKarmaCategoryFilter = "";
          }
          this.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Category Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Category Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    getChildCategories(category_id, reset = true) {
      const self = this;
      let data = {};
      data.url = "karma-categories/get-child-categories/" + category_id;
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.subKarmaCategoryOption = res.data.data;
          if (reset) {
            self.data_local.karma_catalog_id = "";
            self.data_local.sub_karma_category_id = "";
            self.data_local.karma_point = "";
          }
          this.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Child Categories Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });

                this.$toast({
          component: ToastificationContent,
          props: {
            title: `Oops! Child Categories Getting Failed`,
            icon:'BellIcon',
            variant : 'danger',
            hideClose: true,
            
          },
              },
              {
          timeout : 3000,
          position : 'bottom-center',
          toastClassName:"error-info",
          hideProgressBar : true,
              })
        });
    },
    formSubmitted() {
      const self = this;
      this.isBusy = true;
      let data = {};
      data.url = "exports";
      data.params = self.data_local;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.isBusy = false;
          this.refetchData();
          this.data_local.moduleFilter = "";
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Export Started",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Export Started',
                icon: 'EditIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          console.log(error);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Export Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Export Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    fetchRolesList() {
      const self = this;
      self.isBusy = true;

      let data = {};
      data.url = "/list/roles";
      self.$store
        .dispatch("app/get", data)
        .then((response) => {
          self.roleOptions = [{ label: "All", code: 0 }, ...response.data.data];
          self.isBusy = false;
        })
        .catch((error) => {
          self.isBusy = false;
        });
    },
    fetchDesignationsList() {
      this.isBusy = true;
      let data = {};
      data.url = "/list/designations";
      this.$store
        .dispatch("app/get", data)
        .then((response) => {
          this.designationOptions = [
            { label: "All", code: 0 },
            ...response.data.data,
          ];
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
        });
    },
    fetchManagerList() {
      this.isBusy = true;
      let data = {};
      data.url = "/list/managers";
      this.$store
        .dispatch("app/get", data)
        .then((response) => {
          this.managerOptions = response.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
        });
    },
    fetchShiftsList() {
      this.isBusy = true;
      let data = {};
      data.url = "/list/shifts";
      this.$store
        .dispatch("app/get", data)
        .then((response) => {
          this.shiftOptions = [
            { label: "All", code: 0 },
            ...response.data.data,
          ];
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
        });
    },
    fetchEmployeeList() {
      this.isBusy = true;
      let data = {};
      data.url = "list/employees";
      this.$store
        .dispatch("app/get", data)
        .then((response) => {
          this.employeeOptions = response.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
        });
    },
    fetchDepartmentList() {
      this.isBusy = true;
      let data = {};
      data.url = "list/department";
      this.$store
        .dispatch("app/get", data)
        .then((response) => {
          this.departmentOption = response.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
        });
    },
    getVacanciesCustomFields() {
      const self = this;
      this.isBusy = true;
      let data = {};
      data.url = "/vacancies/create";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.vacancyUrgencyStatusOption = [
            { label: "All", code: "all" },
            ...res.data.meta.urgency_status,
          ];
          self.vacancyStatusOption = [
            { label: "All", code: "all" },
            ...res.data.meta.status,
          ];
          self.vacancyTypeOption = [
            { label: "All", code: "all" },
            ...res.data.meta.type,
          ];
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
        });
    },
    editRecord(data) {
      this.$router.push(`/edit/export/${data.item.hashid}`).catch(() => {});
    },
    viewRecord(data) {
      this.$router.push(`/view/export/${data.item.hashid}`).catch(() => {});
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.file_name}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedExports.length} Export, This cannot be undone.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-exports/removeMultipleExports", this.selectedExports)
            .then((res) => {
              this.isDeleteMultipleCandChecked = false;
              this.selectedExports = [];
              if (res.data.success) {
                this.refetchData();
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Deleted Successfully.",
                //     icon: "BellIcon",
                //     variant: "success",
                //     text: res.data.message,
                //   },
                // });

                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Deleted Successfully.',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
              } else {
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Export Deletion failed.",
                //     icon: "BellIcon",
                //     variant: "danger",
                //     text: res.data.message,
                //   },
                // });

                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Export Deletion failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    refetchExportsData() {},
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-exports/removeExport", id)
        .then((res) => {
          if (res.status == "204") {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Export Deleted",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "Export Deleted Successfully.",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Export Deleted',
                icon: 'EditIcon',
                variant: 'success',
                text: 'Export Deleted Successfully.',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Export Deletion failed.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Export Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
          self.refetchData();
        })
        .catch((err) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Export Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
          component: ToastificationContent,
          props: {
            title: `Export Deletion failed`,
            icon:'BellIcon',
            variant : 'danger',
            hideClose: true,
            
          },
              },
              {
          timeout : 3000,
          position : 'bottom-center',
          toastClassName:"error-info",
          hideProgressBar : true,
              })
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
